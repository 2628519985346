<template>
  <component :is="adminData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="adminData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No agency found with this agency id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'agencies'}"
        >
          Agency List
        </b-link>
        for other agency.
      </div>
    </b-alert>

    <b-tabs
      v-if="adminData"
      pills
    >

      <!-- Tab: Profile -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Profil</span>
        </template>

          <admin-edit-tab-profile
          :admin-data="adminData"
          class="mt-2 pt-75"
        />

      </b-tab>
  

      

      <!-- Tab: Information
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab>
      -->

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import AdminEditTabProfile from './AdminEditTabProfile.vue'


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    AdminEditTabProfile

  },
  setup() {
    const adminData = ref(null)

    const ADMIN_APP_STORE_MODULE_NAME = 'app-admins'


    store.dispatch(`${ADMIN_APP_STORE_MODULE_NAME}/fetchAdmin`, { id: router.currentRoute.params.id })
      .then(response => {
        console.error(response.data)
        adminData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          adminData.value = undefined
        }
      })

    const roleOptions = [
      { label: 'Administrator', value: 'organisation_admin' },
      { label: 'Coordinator', value: 'organization_coordinator' },
    ]

    return {
      adminData,
    }
  },
  methods: {
    updateAdmin(adminId) {
      this.$store.dispatch(`${ADMIN_APP_STORE_MODULE_NAME}/updateAdmin`, adminData.value)
        .then(() => {

          // Update count in cart items state
          //this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
        })
    },
  }
}
</script>

<style>

</style>
